
            @import "src/scss/_variables.scss";
            @import "src/scss/_mixins.scss";
          
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: --var(--ff--primary);
}
