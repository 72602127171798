@import url("https://use.typekit.net/jba7eqm.css");

/*********************
  MEDIA QUERIES
  sass vars are limited to media queries due to their lack of support for CSS custom properties.
*********************/
$mq--xs: 414px;
$mq--sm: 768px;
$mq--md: 1136px;
$mq--lg: 1440px;
$mq--xl: 1600px;

:root {
  /*********************
    FONTS
    *********************/
  --ff--primary: "sofia-pro", "Arial", sans-serif;

  --fw--xs: 300;
  --fw--sm: 400;
  --fw--md: 500;
  --fw--lg: 700;
  --fw--xl: 900;

  /*********************
    SPACING
    2px system
    *********************/

  --base-unit: 0.125rem;
  --spacing--2: var(--base-unit);
  --spacing--4: calc(var(--base-unit) * 2);
  --spacing--6: calc(var(--base-unit) * 3);
  --spacing--8: calc(var(--base-unit) * 4);
  --spacing--10: calc(var(--base-unit) * 5);
  --spacing--12: calc(var(--base-unit) * 6);
  --spacing--14: calc(var(--base-unit) * 7);
  --spacing--16: calc(var(--base-unit) * 8);
  --spacing--18: calc(var(--base-unit) * 9);
  --spacing--20: calc(var(--base-unit) * 10);
  --spacing--22: calc(var(--base-unit) * 11);
  --spacing--24: calc(var(--base-unit) * 12);
  --spacing--26: calc(var(--base-unit) * 13);
  --spacing--28: calc(var(--base-unit) * 14);
  --spacing--30: calc(var(--base-unit) * 15);
  --spacing--32: calc(var(--base-unit) * 16);
  --spacing--34: calc(var(--base-unit) * 17);
  --spacing--36: calc(var(--base-unit) * 18);
  --spacing--38: calc(var(--base-unit) * 19);
  --spacing--40: calc(var(--base-unit) * 20);
  --spacing--42: calc(var(--base-unit) * 21);
  --spacing--44: calc(var(--base-unit) * 22);
  --spacing--46: calc(var(--base-unit) * 23);
  --spacing--48: calc(var(--base-unit) * 24);
  --spacing--50: calc(var(--base-unit) * 25);
  --spacing--52: calc(var(--base-unit) * 26);
  --spacing--54: calc(var(--base-unit) * 27);
  --spacing--56: calc(var(--base-unit) * 28);
  --spacing--58: calc(var(--base-unit) * 29);
  --spacing--60: calc(var(--base-unit) * 30);
  --spacing--62: calc(var(--base-unit) * 31);
  --spacing--64: calc(var(--base-unit) * 32);
  --spacing--66: calc(var(--base-unit) * 33);
  --spacing--68: calc(var(--base-unit) * 34);
  --spacing--70: calc(var(--base-unit) * 35);
  --spacing--72: calc(var(--base-unit) * 36);
  --spacing--74: calc(var(--base-unit) * 37);
  --spacing--76: calc(var(--base-unit) * 38);
  --spacing--78: calc(var(--base-unit) * 39);
  --spacing--80: calc(var(--base-unit) * 40);

  /*********************
    COLORS
    *********************/

  --white: #ffffff;
  --black: #000000;

  --black--100: #202020;
  --black--80: #4d4d4d;
  --black--60: #797979;
  --black--40: #a6a6a6;
  --black--20: #d2d2d2;
  --black--10: #e9e9e9;
  --black--05: #f4f4f4;

  --color--primary: #00235c;
  --color--primary-80: #334f7d;
  --color--primary-60: #667b9d;
  --color--primary-40: #99a7be;
  --color--primary-20: #ccd3de;
  --color--primary-dark: #001c4a;

  --color--secondary: #256cf8;
  --color--secondary-80: #5189f9;
  --color--secondary-60: #6698fa;
  --color--secondary-40: #92b6fc;
  --color--secondary-20: #d3e2fe;
  --color--secondary-dark: #1e56c6;

  --color--alert: #d4251c;
  --color--alert--light: #ffc7c5;
  --color--success: #30b64a;
  --color--success--light: #adffbd;
  --color--disabled: #e7ecec;

  --color-text: var(--black);
  --color-text--light: rgba(0, 0, 0, 0.8);
  --color-text--info: var(--black);
  --color-text--disable: var(--black);
  --color-text--accent: var(--black);

  /*********************
    SHADOWS
    *********************/

  --box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  --box-shadow--md: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  --box-shadow--lg: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}
