
            @import "src/scss/_variables.scss";
            @import "src/scss/_mixins.scss";
          
.SearchResults {
  width: 100%;
  background-color: var(--white);
  border-radius: 0.75rem;
  padding: var(--spacing--16);
  box-shadow: var(--box-shadow--md);

  &--heading {
    font-size: var(--spacing--20);
    line-height: var(--spacing--28);
    font-weight: 700;
    color: var(--black--100);
  }
}

.SearchResultItem {
  padding: var(--spacing--12) 0;
  display: flex;

  &--influencerDetails {
    flex-grow: 2;
    display: flex;
  }

  &--profilePicture {
    width: var(--spacing--32);
    border-radius: 50%;
  }

  &--influencerContact {
    padding: 0 var(--spacing--8);
  }

  &--influencerName {
    font-size: var(--spacing--14);
    font-weight: 500;
    color: var(--black);
    word-break: break-all;
  }

  &--influencerEmail {
    font-size: var(--spacing--12);
    font-weight: 500;
    color: var(--black--60);
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &--influencerPrice {
    font-size: var(--spacing--14);
    font-weight: 500;
    color: var(--black);
  }

  &--influencerData {
  padding:2px 2px;
  margin-right: 5px;
	display:inline-block;
	border-radius:3px;
	background: #00235c;
	font-size:12px;
    font-weight: bold;
	color:#fafafa;
  }

}

.SearchResultItem:hover {
  background-color: rgba(0, 102, 255, 0.2);
  border: 0.1rem dashed rgba(0, 102, 255, 0.25);
  cursor: pointer;
}

.loaderContainer {
  text-align: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color:  #00235c;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
