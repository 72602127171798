
            @import "src/scss/_variables.scss";
            @import "src/scss/_mixins.scss";
          
.SearchBar {
  position: relative;

  &--iconContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: var(--spacing--12);
    pointer-events: none;
  }

  &--input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-family: inherit;
    font-weight: var(--fw--sm);
    padding: var(--spacing--16);
    padding-left: var(--spacing--40);
    font-size: toRem(14px);
    box-shadow: inset 0 0px 0px 1px var(--black--10);
    color: #1a202c;
    border: 1px solid #d1d5db;
    border-radius: var(--spacing--12);
    background-color: var(--white);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--color--secondary-40);
    }
  }

  &--input-error {
    border-color: var(--color--alert);
    box-shadow: inset 0 0px 0px 1px var(--color--alert);
  }

  &--button {
    color: var(--white);
    position: absolute;
    right: var(--spacing--10);
    bottom: var(--spacing--10);
    background-color: #00235c;
    border: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: var(--color--primary-80);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--color--secondary-40);
    }
  }
}
