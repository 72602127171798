
            @import "src/scss/_variables.scss";
            @import "src/scss/_mixins.scss";
          
.LandingPage {
  &--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &--SearchBar {
    width: 80vw;

    @media (min-width: $mq--md) {
      width: 60vw;
    }
  }

  &--logo {
    max-width: 15rem;
    cursor: pointer;
  }
}
