
            @import "src/scss/_variables.scss";
            @import "src/scss/_mixins.scss";
          
.SearchResultsPage {
  padding: var(--spacing--4);

  @media (min-width: $mq--sm) {
    padding: var(--spacing--16);
  }

  &--navbar {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &--searchBar {
    width: 100%;

    @media (min-width: $mq--sm) {
      width: 65vw;
    }
  }

  &--logo {
    width: 8rem;
    cursor: pointer;
    padding-right: var(--spacing--8);
  }
}
